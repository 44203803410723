<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Paquete - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Español" active>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Categoria:">
                        <b-form-select  v-model="mpackage.id_package_category" :options="package_category_es" ></b-form-select>
                        <small v-if="errors.id_package_category"  class="form-text text-danger" >Seleccione una categoria</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Codigo:">
                        <b-form-input  v-model="mpackage.code" ></b-form-input>
                        <small v-if="errors.code"  class="form-text text-danger" >Ingrese un codigo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input  v-model="mpackage.title_es" ></b-form-input>
                        <small v-if="errors.title_es"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <tinymce :height="1000" v-model="mpackage.description_es" id='description_es'></tinymce>
                        <small v-if="errors.description_es"  class="form-text text-danger" >Ingrese un descripción</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Itinerario:">
                        <tinymce :height="1800" v-model="mpackage.itinerary_es" id='itinerary_es'></tinymce>
                        <small v-if="errors.itinerary_es"  class="form-text text-danger" >Ingrese un itinerario</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Incluye / No Incluye:">
                        <tinymce :height="1000" v-model="mpackage.incorporate_es" id='incorporate_es'></tinymce>
                        <small v-if="errors.incorporate_es"  class="form-text text-danger" >Ingrese un incluye / no incluye</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duración:">
                        <b-form-input  v-model="mpackage.duration_es"></b-form-input>
                        <small v-if="errors.duration_es"  class="form-text text-danger" >Ingrese una duración</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Precio Anterior:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_old_es"></b-form-input>
                        <small v-if="errors.price_old_es"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Precio:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_es"></b-form-input>
                        <small v-if="errors.price_es"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select  v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Ciber Wow:">
                        <b-form-select v-model="mpackage.cyber_wow" :options="cyber_wow"></b-form-select>
                        <small v-if="errors.cyber_wow"  class="form-text text-danger" >Ingrese una opción</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Puntuación:">
                        <b-form-select v-model="mpackage.score" :options="score"></b-form-select>
                        <small v-if="errors.price_es"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Ubicación:">
                        <b-form-input v-model="mpackage.location"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select  v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="12">
                      <b-form-group label="Especificación:">
                        <tinymce :height="800" v-model="mpackage.specification_es" id='specification_es'></tinymce>
                        <small v-if="errors.specification_es"  class="form-text text-danger" >Ingrese un especificación</small>
                      </b-form-group>
                    </b-col> -->
                    
                    
                  </b-row>
                </b-tab>
                <b-tab title="Ingles">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Category:">
                        <b-form-select  v-model="mpackage.id_package_category" :options="package_category_en" ></b-form-select>
                        <small v-if="errors.id_package_category"  class="form-text text-danger" >Seleccione una categoria</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Code:">
                        <b-form-input  v-model="mpackage.code" ></b-form-input>
                        <small v-if="errors.code"  class="form-text text-danger" >Ingrese un codigo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Title:">
                        <b-form-input  v-model="mpackage.title_en" ></b-form-input>
                        <small v-if="errors.title_en"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Description:">
                        <tinymce :height="1000" v-model="mpackage.description_en" id='description_en'></tinymce>
                        <small v-if="errors.description_en"  class="form-text text-danger" >Ingrese un descripción</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Itinerary:">
                        <tinymce :height="1800" v-model="mpackage.itinerary_en" id='itinerary_en'></tinymce>
                        <small v-if="errors.itinerary_en"  class="form-text text-danger" >Ingrese un itinerario</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Includes / Does not include:">
                        <tinymce :height="1000" v-model="mpackage.incorporate_en" id='incorporate_en'></tinymce>
                        <small v-if="errors.incorporate_en"  class="form-text text-danger" >Ingrese un incluye / no incluye</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duration:">
                        <b-form-input  v-model="mpackage.duration_en"></b-form-input>
                        <small v-if="errors.duration_en"  class="form-text text-danger" >Ingrese un duracion</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Previous Price:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_old_en"></b-form-input>
                        <small v-if="errors.price_old_en"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Price:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_en"></b-form-input>
                        <small v-if="errors.price_en"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select  v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Ciber Wow:">
                        <b-form-select v-model="mpackage.cyber_wow" :options="cyber_wow"></b-form-select>
                        <small v-if="errors.cyber_wow"  class="form-text text-danger" >Ingrese una opción</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Score:">
                        <b-form-select v-model="mpackage.score" :options="score"></b-form-select>
                        <small v-if="errors.price_es"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-form-group label="Location:">
                        <b-form-input v-model="mpackage.location"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select  v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="12">
                      <b-form-group label="Specification:">
                        <tinymce :height="800" v-model="mpackage.specification_en" id='specification_en'></tinymce>
                        <small v-if="errors.specification_en"  class="form-text text-danger" >Ingrese un especificación</small>
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                </b-tab>

                <b-tab title="Imagenes">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Imagen Principal:" >
                          <b-img style="max-height:400px;" fluid :src="url_base+mpackage.image"></b-img>
                      </b-form-group>
                    </b-col>
                    <b-col md="6"></b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="">Cambiar Imagen Principal: <small>Tamaño recomendado (1000x600)px</small></label>
                        <b-form-file
                          accept="image/png, image/jpeg"
                          v-model="file"
                          :state="Boolean(file)"
                          @change="onFileChange"
                          placeholder="Seleccione una imagen"
                          drop-placeholder="Arrastre la imagen aqui"
                        ></b-form-file>
                        <small v-if="errors.image"  class="form-text text-danger" >Seleccione una imagen</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                          <label for="">Agregar Mas Imagenes: <small>Tamaño recomendado (1000x600)px</small></label>
                            <b-input-group>
                              <b-form-file
                                accept="image/png, image/jpeg"
                                v-model="files"
                                ref="uploadfiles"
                                :state="Boolean(files)"
                                multiple
                                placeholder="Seleccione las imagenes"
                                drop-placeholder="Arrastre las imagenes aqui"
                              ></b-form-file>
                              <b-input-group-append>
                                <b-button variant="primary" @click="AddPackagePhoto" >Agregar</b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                    </b-col>



                    <b-col md="12"></b-col>
                    <b-col md="12"><strong>Mas Fotos:</strong></b-col>
                    <b-col md="12"></b-col>
                    <b-col md="3" v-for="(item, it) in images" :key="it">
                      <b-img class="img-fluid" :src="url_base+item.image"></b-img>
                      <b-button class="form-control" @click="ConfirmDeletePhoto(item.id_package_photo)" type="button" variant="danger">Eliminar</b-button>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              

              <b-row>


                <b-col md="12">
                  <br>
                </b-col>
                
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";

// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props:["id_package"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Package',
      role: 3,
      mpackage: {
        id_package: '',
        id_package_category: '',
        code: '',
        title_es: '',
        description_es: '',
        itinerary_es:'',
        incorporate_es:'',
        duration_es: '',
        price_old_es: '0.00',
        price_es: '0.00',
        specification_es: '',
        title_en: '',
        description_en: '',
        itinerary_en:'',
        incorporate_en:'',
        duration_en: '',
        price_old_en:'0.00',
        price_en: '0.00',
        specification_en: '',
        image: '',
        location:'',
        score:5,
        cyber_wow:0,
        image_change:'',
        video: '',
        state: 1,
        favorite: 0,
      },
      file:null,
      files:null,
      images:[],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      score:[
        {value:1, text:'1'},
        {value:2, text:'2'},
        {value:3, text:'3'},
        {value:4, text:'4'},
        {value:5, text:'5'},
      ],
      favorite:[
        {value:'1',text:'SI'},
        {value:'0',text:'NO'},
      ],
      cyber_wow:[
        {value:'1',text:'SI'},
        {value:'0',text:'NO'},
      ],
      package_category_en:[],
      package_category_es:[],

      errors: {
        id_package_category: false,
        code: false,

        title_es: false,
        description_es: false,
        itinerary_es: false,
        incorporate_es: false,
        price_old_es: false,
        specification_es: false,
        duration_es: false,
        price_es: false,

        title_en: false,
        description_en: false,
        itinerary_en: false,
        incorporate_en: false,
        price_old_en: false,
        specification_en: false,
        duration_en: false,
        price_en: false,

        image: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListPackageCategory();
    this.ViewPackage();
  },
  methods: {
    EditPackage,
    Validate,
    onFileChange,
    ListPackageCategory,
    ViewPackage,

    AddPackagePhoto,
    ConfirmDeletePhoto,
    DeletePhoto,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.mpackage.image_change = e.target.files[0];
}

function ViewPackage() {
  let me = this;
  let id_package = je.decrypt(this.id_package);
  let url = me.url_base + "package/view/"+id_package;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mpackage.id_package = response.data.result.package.id_package;
        me.mpackage.id_package_category = response.data.result.package.id_package_category;
        me.mpackage.code = response.data.result.package.code;
        me.mpackage.title_es = response.data.result.package.title_es;
        me.mpackage.description_es = response.data.result.package.description_es;

        me.mpackage.itinerary_es = response.data.result.package.itinerary_es;
        me.mpackage.incorporate_es = response.data.result.package.incorporate_es;
        me.mpackage.price_old_es = response.data.result.package.price_old_es;

        me.mpackage.duration_es = response.data.result.package.duration_es;
        me.mpackage.price_es = response.data.result.package.price_es;
        me.mpackage.specification_es = response.data.result.package.specification_es;
        me.mpackage.title_en = response.data.result.package.title_en;
        me.mpackage.description_en = response.data.result.package.description_en;
        me.mpackage.itinerary_en = response.data.result.package.itinerary_en;
        me.mpackage.incorporate_en = response.data.result.package.incorporate_en;
        me.mpackage.price_old_en = response.data.result.package.price_old_en;
        me.mpackage.duration_en = response.data.result.package.duration_en;
        me.mpackage.price_en = response.data.result.package.price_en;
        me.mpackage.specification_en = response.data.result.package.specification_en;
        me.mpackage.image = response.data.result.package.image;
        me.mpackage.video = response.data.result.package.video;
        me.mpackage.location = response.data.result.package.location;
        me.mpackage.score = response.data.result.package.score;
        me.mpackage.state = response.data.result.package.state;
        me.mpackage.cyber_wow = response.data.result.package.cyber_wow;
        me.mpackage.favorite = response.data.result.package.favorite;

        me.images = response.data.result.photos;

      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ListPackageCategory() {
  let me = this;
  let url = me.url_base + "packages-category/select-active";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      me.package_category_es.push({ value:'',text:'-- Seleccione una categoria --' });
      me.package_category_en.push({ value:'',text:'-- Seleccione una categoria --' });
      if (response.data.status == 200) {
          for (let index = 0; index < response.data.result.length; index++) {
            const element = response.data.result[index];
            me.package_category_es.push({value:element.id_package_category, text:element.title_es});
            me.package_category_en.push({value:element.id_package_category, text:element.title_en});
          }
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditPackage() {
  let me = this;
  let url = me.url_base + "package/edit";
  let data = new FormData();
  data.append("id_package", me.mpackage.id_package);
  data.append("id_package_category", me.mpackage.id_package_category);
  data.append("code", me.mpackage.code);
  data.append("title_es", me.mpackage.title_es);
  data.append("description_es", me.mpackage.description_es);
  data.append("itinerary_es", me.mpackage.itinerary_es);
  data.append("incorporate_es", me.mpackage.incorporate_es);
  data.append("price_old_es", me.mpackage.price_old_es);
  data.append("duration_es", me.mpackage.duration_es);
  data.append("price_es", me.mpackage.price_es);
  data.append("specification_es", me.mpackage.specification_es);

  data.append("title_en", me.mpackage.title_en);
  data.append("description_en", me.mpackage.description_en);
  data.append("itinerary_en", me.mpackage.itinerary_en);
  data.append("incorporate_en", me.mpackage.incorporate_en);
  data.append("price_old_en", me.mpackage.price_old_en);
  data.append("duration_en", me.mpackage.duration_en);
  data.append("price_en", me.mpackage.price_en);
  data.append("specification_en", me.mpackage.specification_en);

  data.append("image", me.mpackage.image_change);
  data.append("video", me.mpackage.video);
  data.append("location", me.mpackage.location);
  data.append("score", me.mpackage.score);
  data.append("cyber_wow", me.mpackage.cyber_wow);
  data.append("state", me.mpackage.state);
  data.append("favorite", me.mpackage.favorite);
  
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
       
          me.mpackage.image = response.data.result.image;
          me.mpackage.image_change = '';
          me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_package_category = this.mpackage.id_package_category.length == 0 ? true : false;
  this.errors.code = this.mpackage.code.length == 0 ? true : false;

  this.errors.title_es = this.mpackage.title_es.length == 0 ? true : false;
  this.errors.description_es = this.mpackage.description_es.length == 0 ? true : false;
  this.errors.itinerary_es = this.mpackage.itinerary_es.length == 0 ? true : false;
  this.errors.incorporate_es = this.mpackage.incorporate_es.length == 0 ? true : false;
  this.errors.duration_es = this.mpackage.duration_es.length == 0 ? true : false;
  this.errors.price_es = this.mpackage.price_es.length == 0 ? true : false;
  // this.errors.specification_es = this.mpackage.specification_es.length == 0 ? true : false;

  // this.errors.title_en = this.mpackage.title_en.length == 0 ? true : false;
  // this.errors.description_en = this.mpackage.description_en.length == 0 ? true : false;
  // this.errors.itinerary_en = this.mpackage.itinerary_en.length == 0 ? true : false;
  // this.errors.incorporate_en = this.mpackage.incorporate_en.length == 0 ? true : false;
  // this.errors.duration_en = this.mpackage.duration_en.length == 0 ? true : false;
  // this.errors.price_en = this.mpackage.price_en.length == 0 ? true : false;
  // this.errors.specification_en = this.mpackage.specification_en.length == 0 ? true : false;

  this.errors.image = this.mpackage.image.length == 0 ? true : false;
  this.errors.state = this.mpackage.state.length == 0 ? true : false;

  if (this.errors.id_package_category) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  if (this.errors.title_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.description_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.itinerary_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.incorporate_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  // if (this.errors.specification_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.title_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.description_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.itinerary_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.incorporate_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  // if (this.errors.specification_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.image) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el paquete ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditPackage();
    }
  });
}

function AddPackagePhoto() {
  let me = this;
  let url = me.url_base + "package/add-package-photos";
  let data = new FormData();
  data.append("id_package", me.mpackage.id_package);
  var files = this.$refs.uploadfiles.files;
  var totalfiles = this.$refs.uploadfiles.files.length;
  for (var index = 0; index < totalfiles; index++) {
    data.append("images[]", files[index]);
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          me.files = null;
          me.ViewPackage();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ConfirmDeletePhoto(id_package_photo) {

  let me = this;
  Swal.fire({
    title: "Esta seguro de eliminar la foto ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeletePhoto(id_package_photo);
    }
  });
}

function DeletePhoto(id_package_photo) {
  let me = this;
  let url = this.url_base + "package/delete-photo/" + id_package_photo;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 3},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.images.map(item => item.id_package_photo).indexOf(response.data.result.id_package_photo);
        me.images.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}
</script>
